<template>
    <div
        class="base-switch"
        :class="['left', 'right'].includes(position) ? 'd-flex flex-row align-items-center' : null"
        :style="baseSwitchStyles"
    >
        <label v-if="position !== 'right'" class="mb-0 text-muted mr-1" :class="labelClass">{{ label }}</label>
        <BFormCheckbox
            v-bind="$attrs"
            v-model="localData"
            class="subtitle text-muted -mr-1"
            value="1"
            unchecked-value="0"
            :switch="isSwitch"
        />
        <div class="self-center">
            <label v-if="position === 'right'" class="subtitle text-muted mb-0" :class="labelClass">
                {{ label }}
            </label>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { BFormCheckbox } from 'bootstrap-vue'
import { inputLabelPositionOptions } from '../../mocks/StorybookOptions.mock'

export default defineComponent({
    name: 'CheckboxSwitchToggle',
    components: {
        BFormCheckbox,
    },
    inheritAttrs: false,
    props: {
        value: {
            type: [Boolean, String, Number],
            default: undefined,
        },
        label: {
            type: String,
            default: undefined,
        },
        position: {
            type: String,
            default: 'top',
            validator: (value: string) => inputLabelPositionOptions.includes(value),
        },
        labelClass: {
            type: String,
            default: undefined,
        },
        isSwitch: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        localData: {
            get() {
                return this.value
            },
            set(value: string | number | boolean) {
                this.$emit('input', value)
            },
        },
        baseSwitchStyles() {
            return {
                'margin-bottom': 0,
            }
        },
    },
})
</script>

<style scoped>
.base-switch {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    font-size: 11px;
    font-weight: bold;
}
</style>
