<template>
    <fieldset class="fieldset">
        <legend>Free Spaces</legend>
        <b-row>
            <b-col cols="5" class="pr-3 free-space-button">
                <b-button
                    v-for="button in freeSpaceButtons"
                    :key="button.value"
                    size="sm"
                    :variant="isPresetDisabled(button.value) ? 'default-secondary' : 'outline-primary'"
                    block
                    class="mb-2"
                    :class="{ 'active-button': selectedPreset === button.value }"
                    :disabled="isPresetDisabled(button.value)"
                    @click="selectPreset(button.value)"
                >
                    {{ button.text }}
                </b-button>
                <b-row class="mt-3 free-space-setup">
                    <div
                        class="font-weight-bold justify-content-end ml-5 mt-1 text-center"
                        style="font-size: 12px; max-width: 50px; white-space: break-spaces"
                    >
                        Choose Image:
                    </div>
                    <div class="ml-2 justify-content-end">
                        <InlineImageBtn
                            v-show="imageId"
                            ref="freeSpaceImage"
                            btn-class="rounded-right-small"
                            :image-id="imageId"
                            is-bingo-free-space
                        />
                        <b-iconstack v-if="!imageId" class="default-image" @click="openInlineImageSelector">
                            <b-icon stacked icon="star-fill" scale="0.75"></b-icon>
                            <b-icon stacked icon="circle"></b-icon>
                        </b-iconstack>
                    </div>
                </b-row>
            </b-col>
            <b-col cols="7">
                <div class="bingo-free-spaces">
                    <div class="bingo-card" :class="['columns-' + dimension]" :style="{ borderColor: style.color }">
                        <div
                            v-for="index in squared"
                            :key="'bingo-square' + dimension + '-' + index"
                            class="bingo-cell"
                            :style="{ borderColor: style.color }"
                            @click="toggleFreeSpace(index)"
                        >
                            <span
                                v-show="isFreeSpace(index)"
                                class="free-space"
                                :style="{
                                    color: style.color,
                                    borderColor: style.color,
                                }"
                            >
                                <InlineImage
                                    v-if="imageId"
                                    class="p-2"
                                    :image-id="imageId"
                                    :min-size="minimumSizes[dimension]"
                                />
                                <b-iconstack v-else>
                                    <b-icon stacked icon="star-fill" scale="0.75"></b-icon>
                                    <b-icon stacked icon="circle"></b-icon>
                                </b-iconstack>
                            </span>
                        </div>
                    </div>
                </div>
                <i v-if="selectedPreset === 3" class="custom-free-space">click to add free spaces</i>
            </b-col>
        </b-row>
    </fieldset>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import InlineImageBtn from './AddInlineImageButton.vue'
import InlineImage from './widgets/InlineImage.vue'
import { ImageObject } from '../types/image'

interface Data {
    showImageSelector: boolean
    selectedPreset: number
    freeSpaceButtons: GenericObject[]
    freeSpacePresets: freeSpacePresets
    minimumSizes: { [key: number]: number }
}

interface GenericObject {
    text: string
    value: number
}

interface freeSpacePresets {
    center: number
    corners: number
    none: number
    custom: number
}

const freeSpaceButtons: GenericObject[] = [
    {
        text: 'Center',
        value: 0,
    },
    {
        text: 'Four Corners',
        value: 1,
    },
    {
        text: 'None',
        value: 2,
    },
    {
        text: 'Custom',
        value: 3,
    },
]

const freeSpacePresets = {
    center: 0,
    corners: 1,
    none: 2,
    custom: 3,
}

const minimumSizes = {
    3: 48,
    4: 48,
    5: 48,
    6: 44,
    7: 40,
}

export default defineComponent({
    name: 'BingoFreeSpaceForm',
    components: { InlineImage, InlineImageBtn },
    data(): Data {
        return {
            showImageSelector: false,
            selectedPreset: 0,
            freeSpaceButtons: freeSpaceButtons,
            freeSpacePresets: freeSpacePresets,
            minimumSizes: minimumSizes,
        }
    },
    computed: {
        ...mapGetters({
            bingo: 'document/bingo',
            style: 'document/bingoStyle',
        }),
        squared(): number {
            return this.dimension * this.dimension
        },
        dimension(): number {
            return Number(this.bingo.dimension)
        },
        imageId(): string | null {
            return this.bingo.inline_images.find((image: ImageObject) => image.id === this.bingo.free_space_image)
                ? this.bingo.free_space_image
                : null
        },
    },
    watch: {
        dimension() {
            this.handlePresetOnDimensionChanges()
            this.preset(this.selectedPreset)
        },
    },
    mounted() {
        this.setPresetOnload()
    },
    methods: {
        async preset(index: number) {
            this.showImageSelector = false
            let freeSpaces = this.bingo.free_spaces

            if (index !== this.freeSpacePresets.custom) {
                freeSpaces = []
            }

            let d = this.dimension
            switch (index) {
                case 0:
                    //center
                    freeSpaces.push(Math.ceil(this.squared / 2))
                    break
                case 1:
                    // four corners
                    freeSpaces.push(1) // push the first
                    freeSpaces.push(d) // push the second
                    freeSpaces.push(d * (d - 1) + 1) // push the Third
                    freeSpaces.push(d * d) // push the last one.
                    break
                default:
                    // none
                    break
            }

            await this.$store.dispatch('document/setBingo', {
                free_spaces: freeSpaces,
                free_space_preset: index,
            })

            this.$emit('change')

            await this.$store.dispatch('document/storeDocumentState')
        },
        isFreeSpace(index: number): boolean {
            return this.bingo.free_spaces.includes(index)
        },
        async toggleFreeSpace(index: number) {
            this.selectPreset(this.freeSpacePresets.custom)
            //clone the free_spaces array
            let freeSpaces = JSON.parse(JSON.stringify(this.bingo.free_spaces))
            let inFreeSpaces = freeSpaces.indexOf(index)

            this.showImageSelector = true

            if (inFreeSpaces >= 0) {
                freeSpaces.splice(inFreeSpaces, 1)
            } else {
                freeSpaces.push(index)
            }
            await this.$store.dispatch('document/setBingo', {
                free_spaces: freeSpaces,
                free_space_preset: this.freeSpacePresets.custom,
            })

            this.$emit('change')

            await this.$store.dispatch('document/storeDocumentState')
        },
        selectPreset(index: number) {
            this.selectedPreset = index

            if (index === this.freeSpacePresets.custom) this.showImageSelector = true

            this.preset(index)
        },
        openInlineImageSelector() {
            const button = (this.$refs.freeSpaceImage as HTMLElement).$el.querySelector('button')
            if (button) {
                button.click()
            }
        },
        async refreshCallList() {
            await this.$store.dispatch('document/scaleDocument', true)
        },
        setPresetOnload() {
            if (!this.bingo.free_spaces.length && !this.bingo.free_space_preset) {
                this.selectedPreset = this.freeSpacePresets.none
                return
            }

            if (this.bingo.free_space_preset) {
                this.selectedPreset = this.bingo.free_space_preset
                return
            }

            if (this.bingo.free_spaces[0] === Math.ceil(this.squared / 2)) {
                this.selectedPreset = this.freeSpacePresets.center
                return
            }

            if (
                this.bingo.free_spaces[0] === 1 &&
                this.bingo.free_spaces[1] === this.dimension &&
                this.bingo.free_spaces[2] === this.dimension * (this.dimension - 1) + 1 &&
                this.bingo.free_spaces[3] === this.dimension * this.dimension
            ) {
                this.selectedPreset = this.freeSpacePresets.corners
                return
            }

            this.selectedPreset = this.freeSpacePresets.center
        },
        isPresetDisabled(value: number): boolean {
            return !value && this.dimension % 2 === 0
        },
        handlePresetOnDimensionChanges() {
            if (this.selectedPreset === this.freeSpacePresets.custom) {
                this.selectedPreset = this.freeSpacePresets.none
                return
            }

            if (this.selectedPreset !== this.freeSpacePresets.center || this.dimension % 2 !== 0) return

            this.selectedPreset = this.freeSpacePresets.none
        },
    },
})
</script>

<style lang="scss">
@import 'Scss/base.scss';
@import 'Scss/runtime.scss';

.bingo-free-spaces {
    .bingo-card {
        border: 1px solid;
        display: grid;
        margin: auto;

        .bingo-cell {
            border: 1px solid;
            background-color: $white;
            display: flex;
            justify-content: center;
            align-items: center;
            padding-top: 100%;
            cursor: pointer;
            position: relative;

            .free-space {
                color: $primary;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
        &.columns-3 {
            grid-template-columns: repeat(3, 1fr);
            grid-template-rows: repeat(3, 1fr);

            .free-space {
                font-size: 44px;
            }
        }
        &.columns-4 {
            grid-template-columns: repeat(4, 1fr);
            grid-template-rows: repeat(4, 1fr);
            .free-space {
                font-size: 34px;
            }
        }
        &.columns-5 {
            grid-template-columns: repeat(5, 1fr);
            grid-template-rows: repeat(5, 1fr);
            .free-space {
                font-size: 26px;
            }
        }
        &.columns-6 {
            grid-template-columns: repeat(6, 1fr);
            grid-template-rows: repeat(6, 1fr);
            .free-space {
                font-size: 22px;
            }
        }
        &.columns-7 {
            grid-template-columns: repeat(7, 1fr);
            grid-template-rows: repeat(7, 1fr);
            .free-space {
                font-size: 20px;
            }
        }
    }
}

.active-button {
    background-color: $primary-rgba-20;
}

.free-space-button {
    .btn:disabled {
        pointer-events: none;
    }
}

.free-space-setup {
    .image-btn {
        height: 40px !important;
        width: 40px !important;
        border-radius: 5px;
    }

    .default-image {
        height: 40px !important;
        width: 40px !important;
        border: 1px solid var(--primary);
        border-radius: 5px;
        cursor: pointer;
        padding: 2px;
    }

    .has-inline-image {
        border-style: solid;
    }

    .resource-box {
        right: -75px;
    }
}

.custom-free-space {
    font-size: 14px;
    text-align: center;
    display: block;
    margin-top: 5px;
}
</style>
