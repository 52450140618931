<!-- TODO:  Fix mutation of props -->
<!-- eslint-disable vue/no-mutating-props -->
<template>
    <div>
        <div class="card-body pb-1 pt-0">
            <div>
                <div class="d-flex flex-row justify-content-between align-items-center ml-0">
                    <OptionButtonSelector
                        :options="matchingTypes"
                        :active="localData.settings.match_type"
                        min-width="6.25rem"
                        variant="primary"
                        size="sm"
                        class="w-60"
                        label="Matching Type"
                        @change="updateMatchingType"
                    />
                    <div class="d-flex flex-column w-25 word-scramble-container">
                        <div class="row align-items-center">
                            <b-form-checkbox
                                v-model="localData.settings.word_scramble.has_word_scramble"
                                class="word-scramble-checkbox font-weight-bold text-muted text-nowrap"
                                name="word_scramble"
                                :value="true"
                                size="xs"
                                @change="updateData"
                            >
                                Word Scramble
                            </b-form-checkbox>
                        </div>
                        <div
                            v-if="localData.settings.word_scramble.has_word_scramble"
                            class="row align-items-center justify-content-between"
                        >
                            <b-form-checkbox
                                v-model="localData.settings.word_scramble.scramble_left"
                                class="p-0 m-0"
                                name="check-button"
                                :value="true"
                                switch
                                size="sm"
                                @change="scrambleLetter"
                            >
                                <span style="margin-left: -4px">Left</span>
                            </b-form-checkbox>
                            <b-form-checkbox
                                v-model="localData.settings.word_scramble.scramble_right"
                                class="p-0 m-0 mr-3"
                                name="check-button"
                                :value="true"
                                switch
                                size="sm"
                                @change="updateData"
                            >
                                <span style="margin-left: -4px">Right</span>
                            </b-form-checkbox>
                        </div>
                    </div>
                </div>

                <Draggable
                    v-bind="{ handle: '.item-handle', animation: 500 }"
                    v-model="localData.pairs"
                    class="matching-pairs"
                    @start="startDrag"
                    @end="endDrag"
                >
                    <div
                        v-for="(item, pairIndex) in localData.pairs"
                        :ref="`matching-pair-${pairIndex}`"
                        :key="'item-' + pairIndex"
                        class="card mt-2"
                        @mouseenter="hovered = { ...hovered, [pairIndex]: true }"
                        @mouseleave="hovered = { ...hovered, [pairIndex]: false }"
                    >
                        <div class="card-header item-handle p-1 d-flex justify-content-between align-items-center">
                            <NumberingButton
                                :id="`matching_widget_${pairIndex}`"
                                :has-number-format="numberFormatOption(pairIndex + 1).value !== 7"
                                :index-info="numberFormatOption(pairIndex + 1)"
                                :btn-class="[
                                    {
                                        'active border-primary text-primary': isActive(pairIndex),
                                        hovered: hovered[pairIndex] && !dragging,
                                    },
                                ]"
                                class="prevent-drag"
                                @click="showNumberFormatDialog(pairIndex)"
                            >
                                <b-popover
                                    v-if="currentNumberFormatIndex === pairIndex"
                                    :target="`matching_widget_${pairIndex}`"
                                    :show.sync="showNumberFormat"
                                    triggers=""
                                >
                                    <div v-click-outside="handleClickOutside" class="popover-body-padding-offset">
                                        <MatchingPreviewFormat
                                            class="mb-1"
                                            :item="localData"
                                            :index="index"
                                            style="width: 140px"
                                            @click="showNumberFormat = false"
                                        />
                                    </div>
                                </b-popover>
                            </NumberingButton>
                            <IconButton
                                v-if="(hovered[pairIndex] || focused[pairIndex]) && !dragging"
                                v-b-tooltip.hover
                                title="Remove"
                                variant="outline-danger"
                                icon="Delete"
                                size="sm"
                                transparent
                                tabindex="-1"
                                :has-border="false"
                                :has-hover="false"
                                @click="removePair(pairIndex)"
                            />
                        </div>
                        <div class="card-body p-1">
                            <div class="position-relative word-matching">
                                <IconButton
                                    class="switch-button position-absolute"
                                    icon="UpDownArrow"
                                    size="xs"
                                    variant="secondary"
                                    style="z-index: 10"
                                    tabindex="-1"
                                    @click="swapPair(item)"
                                />
                                <b-input-group>
                                    <b-form-input
                                        :id="`worksheet-input-${pairIndex}`"
                                        v-model="item.term"
                                        type="text"
                                        class="form-control top"
                                        placeholder="Word"
                                        @change="updateData"
                                        @keydown.enter="focusDefinitionInput(pairIndex)"
                                        @focus="focused = { ...focused, [pairIndex]: true }"
                                        @blur="focused = { ...focused, [pairIndex]: false }"
                                    />
                                    <b-input-group-append>
                                        <InlineImageBtn
                                            :image-id="item.term_image"
                                            :item-index="index"
                                            :input-index="pairIndex"
                                            column="term"
                                            :btn-class="[{ 'border-bottom-0': hideBorder(item) }, 'rounded-top-right-small']"
                                            @deleted="item.term_image = ''"
                                        />
                                    </b-input-group-append>
                                </b-input-group>
                                <b-input-group>
                                    <b-form-input
                                        :id="`worksheet-input-def-${pairIndex}`"
                                        v-model="item.definition"
                                        type="text"
                                        class="form-control border-top-left-0"
                                        placeholder="Match"
                                        @change="updateData"
                                        @keydown.enter="addNewMatchingPair"
                                        @focus="focused = { ...focused, [pairIndex]: true }"
                                        @blur="focused = { ...focused, [pairIndex]: false }"
                                    />
                                    <b-input-group-append>
                                        <InlineImageBtn
                                            class="rounded-top-right-none"
                                            :image-id="item.definition_image"
                                            :item-index="index"
                                            :input-index="pairIndex"
                                            column="definition"
                                            btn-class="rounded-bottom-right-small"
                                            @deleted="item.definition_image = ''"
                                        />
                                    </b-input-group-append>
                                </b-input-group>
                            </div>
                        </div>
                    </div>
                </Draggable>

                <div class="text-center my-2">
                    <!--                    add new-->
                    <div class="card mt-2">
                        <div class="card-body p-1">
                            <div class="position-relative word-matching">
                                <b-input-group>
                                    <b-form-input
                                        :id="`worksheet-input-new`"
                                        v-model="newTerm"
                                        type="text"
                                        class="form-control top"
                                        placeholder="Word"
                                        @keydown.enter="focusDefinitionInput(index)"
                                    />
                                    <b-input-group-append>
                                        <InlineImageBtn
                                            :item-index="index"
                                            :input-index="localData.pairs.length"
                                            column="term"
                                            btn-class="border-bottom-0 rounded-top-right-small"
                                            @click="handleNewInput('term')"
                                        />
                                    </b-input-group-append>
                                </b-input-group>
                                <b-input-group>
                                    <b-form-input
                                        :id="`worksheet-input-def-new`"
                                        v-model="newDefinition"
                                        type="text"
                                        class="form-control border-top-left-0"
                                        placeholder="Match"
                                        @keydown.enter="addNewMatchingPair"
                                    />
                                    <b-input-group-append>
                                        <InlineImageBtn
                                            :item-index="index"
                                            :input-index="localData.pairs.length"
                                            column="definition"
                                            btn-class="rounded-bottom-right-small"
                                            @click="handleNewInput('definition')"
                                        />
                                    </b-input-group-append>
                                </b-input-group>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="d-flex flex-column">
                    <div
                        v-for="(fakeAnswer, fakeIndex) in localData.fakeAnswers"
                        :key="fakeIndex"
                        class="d-flex flex-row fake-answer-input"
                    >
                        <b-input-group class="mb-2 mr-1">
                            <b-form-input
                                :ref="'fake_answer-' + fakeIndex"
                                v-model="localData.fakeAnswers[fakeIndex].text"
                                type="text"
                                class="form-control flex-grow-1"
                                @change="$store.dispatch('document/storeDocumentState')"
                            />
                            <b-input-group-append>
                                <InlineImageBtn
                                    :item-index="index"
                                    :input-index="fakeIndex"
                                    column="fake"
                                    :image-id="localData.fakeAnswers[fakeIndex].image"
                                    btn-class="rounded-right-small"
                                    @deleted="localData.fakeAnswers[fakeIndex].image = ''"
                                />
                            </b-input-group-append>
                        </b-input-group>
                        <button
                            class="btn btn-danger d-flex align-items-center justify-content-center remove-fake-answer-btn"
                            tabindex="-1"
                            @click="removeFakeAnswer(fakeIndex)"
                        >
                            <svg
                                v-b-tooltip.hover
                                title="Remove"
                                class="text-white"
                                height="20"
                                width="20"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                                />
                            </svg>
                        </button>
                    </div>
                    <b-input-group class="fake-answer-input">
                        <b-form-input class="form-control" placeholder="Fake answer" @keydown="addNewFakeAnswer($event)" />
                        <b-input-group-append>
                            <InlineImageBtn
                                :item-index="index"
                                :input-index="localData.fakeAnswers.length"
                                column="fake"
                                btn-class="rounded-right-small"
                                @click="addNewFakeAnswer($event)"
                            />
                        </b-input-group-append>
                    </b-input-group>
                </div>

                <div class="row justify-content-center align-items-center my-2">
                    <button class="btn btn-sm btn-primary" @click="shuffleMatchings()">Shuffle Answers</button>
                </div>

                <AdvancedOptionsToggleButton
                    :active="showAdvancedOptions"
                    @click="showAdvancedOptions = !showAdvancedOptions"
                />

                <div v-if="showAdvancedOptions" class="mt-4">
                    <MatchingFormat
                        :item-style="localStyle"
                        :item="localData.settings"
                        :has-image="hasInlineImage"
                        @updateData="updateFormatData"
                    />

                    <MatchingDots
                        v-if="localData.settings.match_type === 'line'"
                        class="mt-4"
                        :item="localData.settings"
                        @updateData="updateDotsData"
                    />
                    <MatchingLine v-else class="mt-4" :item="localData.settings" @updateData="updateLineData" />
                    <BorderSettings v-model="localStyle" class="mt-4" collapsible @input="updateFormatData" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Draggable from 'vuedraggable'
import MatchingItem from '../../store/models/MatchingItem'
import { mapGetters, mapState } from 'vuex'
import InlineImageBtn from '../AddInlineImageButton.vue'
import InlineImagesMixin from '../../mixins/InlineImages'
import { find } from 'lodash'
import { numberingFormats } from '../../objects/Document'
import MatchingPreviewFormat from '../../widgets/item-manager/components/MatchingPreviewFormat.vue'
import ClickOutside from '../../directives/click-outside'
import EditPayWall from '../../mixins/EditPayWall'
import IconButton from '../../stories/components/buttons/IconButton.vue'
import AdvancedOptionsToggleButton from '../buttons/AdvancedOptionsToggleButton.vue'
import MatchingFormat from '../format-helpers/MatchingFormat.vue'
import MatchingDots from '../format-helpers/MatchingDots.vue'
import MatchingLine from '../format-helpers/MatchingLine.vue'
import BorderSettings from '../../widgets/BorderSettings.vue'
import NumberingButton from '../NumberingButton.vue'
import OptionButtonSelector from '../../stories/components/buttons/OptionButtonSelector.vue'

export default {
    name: 'Matching',
    components: {
        MatchingLine,
        MatchingDots,
        MatchingFormat,
        MatchingPreviewFormat,
        IconButton,
        Draggable,
        InlineImageBtn,
        AdvancedOptionsToggleButton,
        BorderSettings,
        NumberingButton,
        OptionButtonSelector,
    },
    directives: {
        'click-outside': ClickOutside,
    },
    mixins: [InlineImagesMixin, EditPayWall],
    props: {
        data: {
            type: Object,
            required: true,
        },
        index: {
            type: Number,
            required: true,
        },
        itemStyle: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            localStyle: { ...this.itemStyle },
            content: '',
            showAdvancedOptions: false,
            instructions: [
                'Write down the answers in the space provided after each question',
                'Choose the correct answer from the options provided',
                'Trace the following texts',
                'Match each word by the left with their opposite by the right',
            ],
            newTerm: '',
            newDefinition: '',
            newTermImage: '',
            newDefitionImage: '',
            showNumberFormat: false,
            numberFormatOptions: numberingFormats,
            hovered: {},
            focused: {},
            defaultFontSize: 14,
            defaultFontSizeWithImage: 24,
            defaultImageSize: 96,
            currentNumberFormatIndex: null,
            matchingTypes: [
                { label: 'Draw Lines', value: 'line' },
                { label: 'Write Letters', value: 'letter' },
            ],
            dragging: false,
            dragOn: -1,
        }
    },
    computed: {
        ...mapState(['document']),
        ...mapGetters({
            currentWidget: 'document/currentWidget',
            inlineImages: 'document/worksheetInlineImages',
            isLoggedIn: 'user/isLoggedIn',
            isScrollingSideBar: 'document/isScrollingSideBar',
        }),
        localData: {
            get() {
                return this.data
            },
            set(value) {
                this.$emit('change', value)
            },
        },
        inlineImageSettings() {
            return this.localData.settings?.images ?? undefined
        },
        hasInlineImage() {
            return (
                this.localData.pairs.some((pair) => pair.term_image || pair.definition_image) ||
                this.localData.fakeAnswers.some((fake) => fake.image)
            )
        },
    },
    watch: {
        'currentWidget.focusedItem.data.target': {
            deep: true,
            handler() {
                if (this.currentWidget.focusedItem.data.target === 'term') {
                    document.getElementById(`worksheet-input-${this.currentWidget.focusedItem.data.index}`).focus()
                } else if (this.currentWidget.focusedItem.data.target === 'definition') {
                    if (typeof this.currentWidget.focusedItem.data.fake !== 'undefined') {
                        if (this.currentWidget.focusedItem.data.fake) {
                            this.$refs['fake_answer-' + this.currentWidget.focusedItem.data.index][0].focus()
                        } else {
                            document
                                .getElementById(`worksheet-input-def-${this.currentWidget.focusedItem.data.index}`)
                                .focus()
                        }
                    }
                }
            },
        },
        newTerm(val) {
            if (val.trim()) {
                this.handleNewInput('term')
            }
        },
        newDefinition(val) {
            if (val.trim()) {
                this.handleNewInput('definition')
            }
        },
        isScrollingSideBar: {
            handler() {
                this.$nextTick(async () => {
                    this.handleClickOutside()
                    await this.$store.dispatch('document/setIsScrollingSideBar', false)
                })
            },
            immediate: true,
        },
        async hasInlineImage(val) {
            if (val && this.localStyle.font_size === this.defaultFontSize) {
                await this.updateFormatData({ font_size: this.defaultFontSizeWithImage, size: this.defaultImageSize })
            }
        },
        showNumberFormat: {
            handler(newVal) {
                if (!newVal) this.currentNumberFormatIndex = null
            },
            immediate: true,
        },
    },
    mounted() {
        const firstInput = document.getElementById('worksheet-input-0')

        if (!firstInput) return

        firstInput.focus()
    },
    methods: {
        populateInstruction(value) {
            this.content = this.instructions[value]
        },
        addNewMatchingPair() {
            this.localData.pairs.push(new MatchingItem())
            this.updateData()

            this.$nextTick(function () {
                this.setFocus()
            })
        },
        getFocus(index) {
            return document.getElementById(`worksheet-input-${index}`)
        },
        setFocus(input = 'term') {
            let index = this.localData.pairs.length - 1
            if (input === 'definition') {
                index = `def-${index}`
            }
            let elem = this.getFocus(index)
            if (elem) {
                elem.focus()
                this.$nextTick(() => {
                    const scrollContainer = document.getElementById('goto-container')
                    const lastMatchingPair = this.$refs['matching-pair-0'][0]
                    if (scrollContainer && lastMatchingPair) {
                        scrollContainer.scrollTop = scrollContainer.scrollTop + lastMatchingPair.clientHeight
                    }
                })
            }
        },
        focusDefinitionInput(index) {
            let elem = document.getElementById(`worksheet-input-def-${index}`)
            if (elem) {
                elem.focus()
            }
        },
        shuffleMatchings(times) {
            if (!times) {
                times = 5
            }

            let promises = []
            let delay = 1
            for (let i = 0; i < times; i++) {
                promises.push(
                    new Promise((resolve) => {
                        setTimeout(() => {
                            this.localData.shuffle_seed = Math.floor(Math.random() * 9999)
                            resolve()
                        }, delay)
                        delay += 100
                    }),
                )
            }

            // when all the items have been saved set is_loading to false
            Promise.all(promises).then(() => {
                this.updateData()
            })
        },
        scrambleLetter(checked) {
            if (!checked) return

            let times = 5
            let promises = []
            let delay = 1
            for (let i = 0; i < times; i++) {
                promises.push(
                    new Promise((resolve) => {
                        setTimeout(() => {
                            this.localData.pairs.forEach((pair) => {
                                pair.shuffle = Math.floor(Math.random() * 9999)
                            })
                            resolve()
                        }, delay)
                        delay += 100
                    }),
                )
            }

            // when all the items have been saved set is_loading to false
            Promise.all(promises).then(() => {
                this.updateData()
            })
        },
        swapPair(pair) {
            this.swapItem(pair)
        },
        removePair(index) {
            this.localData.pairs.splice(index, 1)
            this.updateData()
        },
        updateData() {
            this.$emit('change', this.localData)
        },
        addNewFakeAnswer(evt) {
            if (typeof evt == 'undefined' || evt.key !== 'Tab') {
                //get the fake answers length
                let index = this.localData.fakeAnswers.length

                //push a fake answer
                this.localData.fakeAnswers.push({ text: '', image: null })

                this.updateData()
                this.$nextTick(async () => {
                    this.$refs['fake_answer-' + index][0].$el.focus()
                    await this.resizeDoc()
                })
            }
        },
        removeFakeAnswer(index) {
            this.localData.fakeAnswers.splice(index, 1)

            this.updateData()
            this.$nextTick(async () => {
                await this.resizeDoc()
            })
        },
        swapItem(item) {
            let term = item.term
            let termImage = item.term_image
            item.term = item.definition
            item.definition = term
            item.term_image = item.definition_image
            item.definition_image = termImage
        },
        handleNewInput(input = 'term') {
            this.localData.pairs.push(
                new MatchingItem(this.newTerm, this.newDefinition, this.newTermImage, this.newDefitionImage),
            )

            this.updateData()
            this.$nextTick(async () => {
                this.setFocus(input)
                await this.resizeDoc()
                this.newTerm = ''
                this.newDefinition = ''
                this.newTermImage = ''
                this.newDefinitionImage = ''
            })
        },
        async resizeDoc() {
            await this.$store.dispatch('document/scaleDocument', true)
        },
        async updateFormatData(data) {
            this.localStyle.index = this.index
            this.localStyle.font_size = parseInt(data.font_size)
            this.localData.settings.images = {
                alignment: data.images_alignment || this.localData.settings.images?.alignment || 'start',
                size: data.size || this.localData.settings.images?.size || 96,
                text_placement: data.text_placement || this.localData.settings.images?.text_placement || 'below',
            }
            this.updateData()
            await this.$store.dispatch('document/updateWidgetStyles', this.localStyle)
        },
        updateLineData(data) {
            this.localData.settings.blank_position = data.blank_position
            this.localData.settings.align_line_horizontally = data.align_line_horizontally

            this.updateData()
        },
        updateDotsData(data) {
            this.localData.settings.show_dots_left = data.show_dots_left
            this.localData.settings.show_dots_right = data.show_dots_right
            this.localData.settings.align_dots_horizontally = data.align_dots_horizontally
            this.localData.settings.align_dots_vertically = data.align_dots_vertically

            this.updateData()
        },
        numberFormatOption(indexNumber) {
            if (!this.localData || (!this.localData.settings.left_column_format && this.localData.settings.display_index))
                return {
                    number: indexNumber,
                    format: 2,
                }

            if (!this.localData.settings.display_index) {
                return {
                    number: indexNumber,
                    format: 7,
                }
            }

            for (const option of this.numberFormatOptions) {
                if (option.value === this.localData.settings.left_column_format) return option

                if (option.subOptions) {
                    const subOption = find(
                        option.subOptions,
                        (subOption) => parseInt(subOption.value) === this.localData.settings.left_column_format,
                    )
                    if (subOption)
                        return {
                            number: indexNumber,
                            format: parseInt(subOption.value),
                        }
                }
            }

            return {
                number: indexNumber,
                format: 2,
            }
        },
        handleClickOutside() {
            this.showNumberFormat = false
        },
        showNumberFormatDialog(index) {
            if (this.currentNumberFormatIndex !== index) {
                this.showNumberFormat = true
                this.currentNumberFormatIndex = index
            } else {
                this.showNumberFormat = !this.showNumberFormat
            }
        },
        updateMatchingType(type) {
            this.localData.settings.match_type = type
            this.updateData()
        },
        isActive(index) {
            return (
                (this.focused[index] || (this.showNumberFormat && this.currentNumberFormatIndex === index)) && !this.dragging
            )
        },
        startDrag({ oldIndex }) {
            this.dragOn = oldIndex
            this.currentNumberFormatIndex = null
            this.hovered = {}
            this.focused = {}
            this.dragging = true
        },
        endDrag({ newIndex }) {
            if (this.dragOn !== -1) {
                this.currentNumberFormatIndex = newIndex
            }
            this.dragOn = -1
            this.dragging = false
        },
    },
}
</script>

<style lang="scss" scoped>
.fake-answer-input {
    padding-right: 0.34rem;
    padding-left: 0.3rem;
}
.remove-fake-answer-btn {
    z-index: 1;
    height: 38px;
    width: 47px;
    padding: 0.25rem 0.4rem;
}

.popover-body-padding-offset {
    padding: 0 0.5rem 0.75rem 0.75rem;
    margin: -1rem -0.25rem -0.75rem -0.5rem;
}

.w-60 {
    width: 60%;
}
.word-scramble-container {
    min-height: 3rem;
}

.word-scramble-checkbox {
    padding: 0;
    margin-left: -0.625rem;
}

.border-top-left-0 {
    border-top-left-radius: 0;
}
</style>
