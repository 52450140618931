<template>
    <div>
        <div class="card-body p-2 border rounded">
            <div class="row align-items-start ml-0 bingo-top-word-row">
                <div class="justify-content-start align-items-center ml-0">
                    <label for="dimension-select" class="font-weight-bold text-muted mb-1 f-11">Grid Size</label>
                    <b-form-select
                        id="dimension-select"
                        class="d-flex"
                        name="dimension-select"
                        :value="bingo.dimension"
                        :options="bingoCardDimensions"
                        size="sm"
                        style="width: 75px"
                        @change="updateBingoDimension($event)"
                    ></b-form-select>
                </div>
                <div class="d-flex flex-column customize-grid">
                    <div class="d-flex flex-column">
                        <b-form-checkbox
                            id="show_top_word"
                            v-model="bingo.show_top_word"
                            class="ml-1"
                            name="show_top_word"
                            :value="1"
                            :unchecked-value="0"
                            @change="updateData"
                        >
                            <span for="show_top_word" class="font-weight-bold text-muted mb-0 f-11">Top Word</span>
                        </b-form-checkbox>
                    </div>
                    <SpreadInput
                        v-model="bingoTopWord"
                        class="mt-1"
                        :length="cardDimension"
                        :style="spreadInputStyle"
                        @change="updateData"
                    ></SpreadInput>
                </div>
            </div>
        </div>
        <div class="card-body p-2 border rounded mt-2">
            <BingoFreeSpaceForm @change="updateData"></BingoFreeSpaceForm>
        </div>

        <div class="card-body p-2 border rounded mt-2">
            <fieldset class="fieldset">
                <legend>Cards</legend>
            </fieldset>

            <div class="d-flex align-items-center">
                <label class="mb-0">
                    <span class="mr-2">Cards Needed:</span>
                </label>
                <b-form-select
                    v-model="bingoCardsNeeded"
                    :clearable="false"
                    item-value="key"
                    label="key"
                    class="w-50"
                    value-field="key"
                    text-field="key"
                    :options="bingoCardNumbers"
                    size="sm"
                    aria-label="Number of cards needed"
                    @change="updateData"
                ></b-form-select>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue'
import { mapGetters, mapState } from 'vuex'
import SpreadInput from '../SpreadInput.vue'
import BingoFreeSpaceForm from '../BingoFreeSpaceForm.vue'

export default defineComponent({
    name: 'BingoCardSetup',
    components: {
        SpreadInput,
        BingoFreeSpaceForm,
    },
    props: {
        data: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            itemData: {
                ...this.data,
            },
        }
    },
    computed: {
        ...mapState(['user']),
        ...mapGetters({
            bingo: 'document/bingo',
            style: 'document/documentStyle',
        }),
        bingoTopWord: {
            get() {
                return this.bingo.top_word
            },
            set(newVal) {
                let updates = {
                    top_word: newVal,
                }
                this.$store.dispatch('document/setBingo', updates)
                this.$store.dispatch('document/storeDocumentState')
            },
        },
        bingoCardsNeeded: {
            get() {
                return this.bingo.num_of_cards
            },
            set(value) {
                this.$store.dispatch('document/setBingo', {
                    num_of_cards: Number(value),
                })
                this.$store.dispatch('document/storeDocumentState')
            },
        },
        cardDimension() {
            return this.bingo.dimension
        },
        bingoCardDimensions() {
            let options = {}
            for (let i = 3; i <= 7; i++) {
                options[i] = i + ' × ' + i
            }
            return options
        },
        bingoCardNumbers() {
            let options = []
            for (let i = 1; i <= 200; i++) {
                let option = {
                    key: i,
                    is_premium: i > this.user.ab_tests['premium-bingo-cards'] ? true : false,
                }
                options.push(option)
            }
            return options
        },
        spreadInputStyle() {
            if (this.bingo.show_top_word) {
                return {
                    opacity: 1,
                    pointerEvents: 'auto',
                }
            }

            return {
                opacity: 0,
                pointerEvents: 'none',
            }
        },
    },
    methods: {
        updateData() {
            this.$emit('change', this.itemData)
        },
        updateBingoDimension(value) {
            let updates = {
                dimension: value,
                free_spaces: [],
            }
            this.$store.dispatch('document/setBingo', updates)
            this.$store.dispatch('document/storeDocumentState')
            this.updateData()
        },
    },
})
</script>

<style scoped>
.bingo-top-word-row {
    min-height: 56px;
}

.customize-grid {
    margin-left: 2.3rem;
}
</style>
