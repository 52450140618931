<template>
    <div>
        <Draggable
            v-model="options"
            v-bind="{ handle: '.multi-option-handle', animation: 250 }"
            :disabled="disabled"
            @end="handleDragEnd"
        >
            <div
                v-for="(option, index) in options"
                :key="index"
                :class="{ 'sub-options-wrapper p-2 mb-3': hasSubOptions }"
                @mouseenter="updateState('hovered', index, true)"
                @mouseleave="updateState('hovered', index, false)"
            >
                <MultiOptionItem
                    :ref-id="`${parentIndex}-choice-item-${index}`"
                    :value="variant === 'word-scramble' ? option : option.answer"
                    :placeholder="isListItems ? `Item ${parseInt(index) + 1}` : 'Option'"
                    :disabled="disabled"
                    :input-class="{ 'restore-border-radius': hasSingleOption }"
                    :delete-button-class="deleteButtonClass(index)"
                    handle
                    handle-class="multi-option-handle"
                    group-class="option-item-group"
                    class="mb-2"
                    @input="updateOption(index, $event)"
                    @enter="handleEnterKey(index, $event)"
                    @delete="removeOption(index)"
                    @focus="updateState('active', index, true)"
                    @blur="updateState('active', index, false)"
                >
                    <template #actions>
                        <span v-if="includeAnswerCheck || variant === 'word-scramble'" class="d-flex ml-2">
                            <b-form-checkbox
                                v-if="includeAnswerCheck"
                                :id="`correct-checkbox-${index}`"
                                :key="`option-${index}`"
                                v-model="option.correct"
                                name="options"
                                class="f-14 text-muted ml-1 mr-n2"
                                :class="{ 'restore-border-radius': hasSingleOption }"
                                tabindex="-1"
                                switch
                                :value-field="option.correct"
                                :disabled="disabled"
                                @change="updateData"
                            ></b-form-checkbox>
                            <b-button
                                v-else-if="variant === 'word-scramble'"
                                v-b-tooltip.hover
                                variant="primary"
                                size="sm"
                                tabindex="-1"
                                title="Re-scramble"
                                :style="{
                                    opacity: option === '' && hasSingleOption ? 0 : 1,
                                    pointerEvents: option === '' && hasSingleOption ? 'none' : 'auto',
                                }"
                                @click="$emit('scramble', index)"
                            >
                                <b-icon size="sm" icon="arrow-repeat"></b-icon>
                            </b-button>
                        </span>
                    </template>
                </MultiOptionItem>
                <div v-if="hasSubOptions" class="d-flex flex-column ml-5">
                    <div>
                        <label
                            for="word-scramble-multi-options"
                            class="f-11 text-muted d-flex justify-content-between font-weight-bold mt-2 mb-1"
                        >
                            <div>Answer Options</div>
                            <div>Answer Key</div>
                        </label>
                    </div>
                    <Draggable
                        v-model="internalSubOptionsState"
                        v-bind="{ handle: '.multi-option-handle', animation: 250 }"
                        :disabled="disabled"
                        @end="handleSubDragEnd"
                    >
                        <div
                            v-for="(subOption, subOptionIndex) in subOptions"
                            :key="subOptionIndex"
                            @mouseenter="updateState('subOptionHovered', subOptionIndex, true)"
                            @mouseleave="updateState('subOptionHovered', subOptionIndex, false)"
                        >
                            <template v-if="subOption.term_index === index">
                                <MultiOptionItem
                                    :ref-id="`${parentIndex}-choice-item-sub${subOptionIndex}`"
                                    :value="subOption.answer"
                                    :placeholder="isListItems ? `Item ${parseInt(subOption.sub_index) + 1}` : 'Option'"
                                    :disabled="disabled"
                                    :input-class="{ 'restore-border-radius': hasSingleSubOption(index) }"
                                    :delete-button-class="deleteSubOptionButtonClass(index, subOptionIndex)"
                                    handle
                                    handle-class="multi-option-handle"
                                    group-class="sub-option-item-group"
                                    class="mb-2"
                                    @input="updateSubOption(subOptionIndex, $event)"
                                    @keydown.enter="handleSubOptionEnterKey(subOptionIndex, index, $event)"
                                    @focus="updateState('subOptionActive', subOptionIndex, true)"
                                    @blur="updateState('subOptionActive', subOptionIndex, false)"
                                    @delete="removeSubOption(index)"
                                >
                                    <template #actions>
                                        <span class="d-flex ml-1">
                                            <b-form-checkbox
                                                :id="`correct-checkbox-sub${subOptionIndex}`"
                                                :key="`suboption-${subOptionIndex}`"
                                                v-model="subOption.correct"
                                                tabindex="-1"
                                                name="options"
                                                class="f-14 text-muted mr-n2 ml-2"
                                                switch
                                                :value-field="subOption.correct"
                                                :disabled="disabled"
                                                @change="updateSubData(null)"
                                            ></b-form-checkbox>
                                        </span>
                                    </template>
                                </MultiOptionItem>
                            </template>
                        </div>
                    </Draggable>
                    <div class="d-flex mb-2">
                        <b-input
                            ref="add-new-multi-option"
                            v-model="newMultiSubOption[index]"
                            :class="{ 'new-word-scramble': variant === 'word-scramble' }"
                            type="text"
                            :style="{ width: subOptionWidth, marginLeft: marginLeftDefault }"
                            size="sm"
                            list="add-new-multi-option"
                            :placeholder="newWordPlaceholder"
                            :disabled="disabled"
                            class="sub-option-item-new"
                            @input="addSubOption(index, $event)"
                        />
                    </div>
                </div>
            </div>
        </Draggable>
        <div class="d-flex my-2">
            <b-input
                ref="add-new-multi-option"
                v-model="newMultiOption"
                :class="{ 'new-word-scramble': variant === 'word-scramble' }"
                :style="{ width: optionWidth, marginLeft: hasSubOptions ? '2.71rem' : marginLeftDefault }"
                :placeholder="newWordPlaceholder"
                :disabled="disabled"
                type="text"
                size="sm"
                list="add-new-multi-option"
                class="option-item-new"
            />
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue'
import Draggable from 'vuedraggable'
import MultiOptionItem from './MultiOptionItem.vue'
import WithItemEventState from '../mixins/WithItemEventState'
export default defineComponent({
    name: 'MultiOptions',
    components: {
        Draggable,
        MultiOptionItem,
    },
    mixins: [WithItemEventState],
    props: {
        value: {
            type: Array,
            default: () => [],
        },
        focusIndex: {
            type: Number,
            default: -1,
        },
        hasAnswer: {
            type: Boolean,
            default: true,
        },
        isListItems: {
            type: Boolean,
            default: false,
        },
        parentIndex: {
            type: Number,
            default: -1,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        variant: {
            type: String,
            default: 'multiple-choice',
        },
        focus: {
            type: Boolean,
            default: false,
        },
        hasSubOptions: {
            type: Boolean,
            default: false,
        },
        subOptionsChoices: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            newMultiOption: '',
            options: [],
            optionWidth: '100%',
            subOptionWidth: '61.5%',
            marginLeftDefault: '2.125rem',
            newMultiSubOption: [],
            internalSubOptionsState: this.subOptionsChoices,
        }
    },
    computed: {
        hasSingleOption: function () {
            return this.options.length === 1
        },
        includeAnswerCheck: function () {
            if (this.variant === 'word-scramble') return false

            return this.hasAnswer ?? true
        },
        newWordPlaceholder: function () {
            if (this.variant === 'word-scramble') return '+Add new word'
            return this.isListItems ? 'Add Item' : 'Add Option'
        },
        subOptions: {
            get() {
                return this.subOptionsChoices
            },
            set(value) {
                this.subOptions = value
            },
        },
    },
    watch: {
        options: {
            handler() {
                if (this.variant === 'word-scramble') return
                this.$emit('input', this.options)
                this.resizeOptionsWidth()
            },
            deep: true,
        },
        newMultiOption(val) {
            if (val.trim()) {
                this.addOption(val.trim())
            }
        },
        focusIndex: {
            immediate: true,
            handler(val) {
                if (val !== null && this.getOptionInputElement(val)) {
                    this.focusItem(val)
                }

                this.resizeOptionsWidth()
            },
        },
        hasSubOptions(val) {
            if (!val) {
                this.resizeOptionsWidth()
                return
            }

            this.options.forEach((_, index) => {
                this.newMultiSubOption[index] = ''
                this.$nextTick(() => {
                    this.addSubOption(index, '', true)
                })
            })

            this.resizeOptionsWidth()
        },
    },
    mounted() {
        this.options = this.value
        this.$nextTick(() => {
            const firstInput = this.getOptionInputElement(0)
            if (firstInput) {
                this.resizeOptionsWidth()
            }

            if (this.focus && firstInput) {
                firstInput.focus()
            }

            if (this.focusIndex !== null) {
                this.focusItem(this.focusIndex)
            }
        })
    },
    methods: {
        updateOption(index, value) {
            if (this.variant === 'word-scramble') {
                this.$nextTick(() => {
                    this.options[index] = value
                    this.updateData()
                })
                return
            }

            this.options[index].answer = value
            this.updateData()
        },
        removeOption(index) {
            this.$nextTick(() => {
                this.options.splice(index, 1)
                const newSubOptions = this.subOptions.filter((subOption) => {
                    if (subOption.term_index === index) return false

                    if (subOption.term_index > index) {
                        subOption.term_index = subOption.term_index - index
                    }
                    return true
                })

                this.updateSubData(newSubOptions)
                this.resizeOptionsWidth()
                this.updateData()
            })
        },
        removeSubOption(subOptionIndex) {
            this.subOptions.splice(subOptionIndex, 1)
            this.resizeOptionsWidth()
            this.updateSubData()
        },
        addOption(val = '') {
            if (this.variant === 'word-scramble') {
                this.options.push(val)

                if (this.hasSubOptions) {
                    this.addSubOption(this.options.length - 1, '', true)
                }

                this.updateData()
                this.$nextTick(() => {
                    let index = this.options.length - 1
                    this.getOptionInputElement(index)?.focus()
                    this.newMultiOption = ''
                })

                this.resizeOptionsWidth()
                this.updateData()
                return
            }

            this.options.push({
                answer: val,
                correct: false,
            })

            this.resizeOptionsWidth()
            this.updateData()

            this.$nextTick(() => {
                let index = this.options.length - 1
                this.getOptionInputElement(index)?.focus()
                this.newMultiOption = ''
            })
        },
        addSubOption(index, val = '', isNew = false) {
            const subOptionsFromIndex = this.getSubOptionsFromIndex(index)
            let subIndex = subOptionsFromIndex.length

            if (isNew) {
                this.newMultiSubOption[index] = ''
                this.subOptions.push(
                    {
                        sub_index: subIndex++,
                        term_index: index,
                        answer: '',
                        correct: false,
                    },
                    {
                        sub_index: subIndex++,
                        term_index: index,
                        answer: '',
                        correct: false,
                    },
                )

                this.updateSubData()
                return
            }

            this.subOptions.push({
                sub_index: subOptionsFromIndex.length,
                term_index: index,
                answer: val,
                correct: false,
            })

            this.$nextTick(() => {
                this.newMultiSubOption[index] = ''
                const subOptionElement = this.getSubOptionInputElement(this.subOptions.length - 1)
                subOptionElement?.focus()
                this.$forceUpdate()
            })

            this.resizeOptionsWidth()
            this.updateSubData()
        },
        focusItem(val) {
            this.getOptionInputElement(val)?.focus()
            this.$emit('clearFocusIndex')
        },
        focusSubItem(val) {
            this.getSubOptionInputElement(val)?.focus()
            this.$emit('clearFocusIndex')
        },
        handleDragEnd(event) {
            if (this.variant !== 'word-scramble') return
            const oldIndex = this.subOptions.filter((subOption) => subOption.term_index === event.oldIndex)
            const newIndex = this.subOptions.filter((subOption) => subOption.term_index === event.newIndex)

            oldIndex.forEach((subOption) => {
                subOption.term_index = event.newIndex
            })

            newIndex.forEach((subOption) => {
                subOption.term_index = event.oldIndex
            })

            this.updateData()
            this.updateSubData()
        },
        handleSubDragEnd(event) {
            const { oldIndex, newIndex } = event

            if (oldIndex === newIndex) return

            const movedItem = this.subOptions.splice(oldIndex, 1)[0]

            this.subOptions.splice(newIndex, 0, movedItem)

            this.subOptions.forEach((item, index) => {
                item.sub_index = index
            })

            this.updateSubData()
        },
        handleEnterKey(index, event) {
            if (event.ctrlKey && event.key === 'Enter') return

            if (index < this.options.length - 1) {
                this.focusItem(index + 1)
                return
            }

            this.addOption('')
        },
        handleSubOptionEnterKey(subIndex, index, event) {
            if (event.ctrlKey && event.key === 'Enter') return

            const subOptionsFromIndex = this.getSubOptionsFromIndex(index)

            if (this.subOptions[subIndex].sub_index < subOptionsFromIndex.length - 1) {
                this.focusSubItem(subIndex + 1)
                return
            }

            this.addSubOption(index)
            this.updateSubData()
        },
        updateSubOption(subIndex, value) {
            const subOption = this.subOptions[subIndex]
            subOption.answer = value
            this.newMultiSubOption[subOption.term_index] = ''

            this.updateSubData()
        },
        getSubOptionsFromIndex(index) {
            const subOptionsFromIndex = this.subOptions.filter((subOption) => subOption.term_index === index)
            subOptionsFromIndex.forEach((subOption, subOptionIndex) => {
                if (subOption.term_index !== index) return

                subOption.sub_index = subOptionIndex
            })

            return subOptionsFromIndex
        },
        hasSingleSubOption(index) {
            const subOptions = this.getSubOptionsFromIndex(index)
            return !(subOptions.length > 1)
        },
        updateData() {
            this.$emit('input', this.options)
        },
        updateSubData(value = null) {
            this.$emit('subOptions', value ? value : this.subOptions)
        },
        resizeOptionsWidth() {
            this.$nextTick(() => {
                const inputElement = this.getOptionInputElement(0)

                if (inputElement) {
                    this.optionWidth = inputElement.getBoundingClientRect().width + 'px'
                }
            })
        },
        showScrambleButton(option) {
            return this.variant === 'word-scramble' && option !== '' && this.hasSingleOption
        },
        getOptionInputElement(index) {
            return document.getElementById(`${this.parentIndex}-choice-item-${index}`)
        },
        getSubOptionInputElement(index) {
            return document.getElementById(`${this.parentIndex}-choice-item-sub${index}`)
        },
        deleteButtonClass(index) {
            return !this.hasSingleOption && (this.hovered[index] || this.active[index]) ? 'visible' : 'hidden'
        },
        deleteSubOptionButtonClass(index, subOptionIndex) {
            return !this.hasSingleSubOption(index) &&
                (this.subOptionHovered[subOptionIndex] || this.subOptionActive[subOptionIndex])
                ? 'visible'
                : 'hidden'
        },
    },
})
</script>

<style lang="scss" scoped>
@import 'Scss/base.scss';

.new-word-scramble {
    font-style: italic;
}

.checklist {
    .option-item-new {
        width: 16.8rem !important;
    }
}

.sub-options-wrapper {
    background: #f7f7f7;
    outline: 1px solid #e2e2e2;
    border-radius: 0.25rem !important;

    .option-item-new {
        width: 16.83125rem !important;
    }

    .sub-option-item-new {
        width: 10.83125rem !important;
    }
}
</style>
