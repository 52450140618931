<template>
    <div class="card-body py-1">
        <div class="d-flex mb-1">
            <!-- Handwriting Style Selector -->
            <OptionButtonSelector
                :options="handwritingStyles"
                :active="localData.cursive_style"
                label-class="mb-0"
                variant="primary"
                size="sm"
                class="w-50"
                label="Handwriting Style"
                min-width="4.25rem"
                @change="updateHandwritingStyle"
            />

            <!-- Handwriting Font Dropdown -->
            <div class="d-flex p-0 d-flex flex-column w-50">
                <label class="font-weight-bold mb-0 f-11 text-muted">
                    {{ localData.cursive_style.charAt(0).toUpperCase() + localData.cursive_style.slice(1) }}
                    Font
                </label>
                <div :id="uniqueId">
                    <FontSelect
                        id="hw-font"
                        :key="'hw-font'"
                        class="mt-0 mb-0 pb-0 w-100"
                        :font="currentFont"
                        :options="false"
                        :fonts="hwFonts"
                        size="sm"
                        grouped
                        @changed="onChangeFont"
                    />
                </div>
            </div>
        </div>

        <!-- Handwriting Items -->
        <Draggable v-model="localData.items" handle=".handle-bar" :animation="250" @change="updateData">
            <HandwritingInputItem
                v-for="(item, itemIndex) in localData.items"
                :ref="item.id"
                :key="`handwriting-${item.id}-${itemIndex}`"
                :item="item"
                :index="itemIndex"
                :font="currentFont"
                @change="updateItemData"
                @duplicate="duplicateItem"
                @delete="removeItem"
                @move-focus="handleSwitchFocus"
            />
        </Draggable>

        <!-- New Handwriting Item -->
        <HandwritingInputItem
            :ref="`handwriting-item-new`"
            :item="newItem"
            :index="-1"
            :font="currentFont"
            class="mt-3"
            @change="updateItemData"
            @add="addItem"
        />

        <!-- Advanced Options -->
        <AdvancedOptionsToggleButton :active="showAdvancedOptions" @click="showAdvancedOptions = !showAdvancedOptions" />

        <fieldset v-if="showAdvancedOptions" class="fieldset mt-2">
            <legend class="fieldset-legend">Line Format</legend>
            <div class="d-flex align-items-baseline">
                <div class="w-60 mr-4">
                    <div class="d-flex justify-content-between align-items-end mt-2">
                        <label class="font-weight-bold mb-0 f-11 text-muted">Height</label>
                        <span class="f-10 text-muted">{{ lineHeightInches }} in</span>
                    </div>
                    <div>
                        <b-form-input
                            v-model="lineHeightInches"
                            type="range"
                            :min="HANDWRITING_LINE_HEIGHT_MIN"
                            :max="HANDWRITING_LINE_HEIGHT_MAX"
                            step="0.01"
                            @input="updateData"
                        ></b-form-input>
                    </div>
                </div>

                <div class="">
                    <div class="d-flex justify-content-between align-items-end mt-2">
                        <label class="font-weight-bold mb-0 f-11 text-muted">Spacing</label>
                        <span class="f-10 text-muted">{{ spacingInches }} in</span>
                    </div>
                    <div>
                        <b-form-input v-model="spacingInches" type="range" min="0" max="2" step="0.05"></b-form-input>
                    </div>
                </div>
            </div>

            <div class="d-flex align-items-baseline">
                <div class="w-60 mr-3">
                    <OptionButtonSelector
                        :options="colorOptions"
                        :active="localData.color_option || 'solid'"
                        :style="{ minHeight: '3.6rem' }"
                        feature="color"
                        label-class="mb-1"
                        variant="primary"
                        size="sm"
                        label="Color"
                        min-width="6rem"
                        @change="updateColorOption"
                    />
                </div>
                <div
                    class="d-flex align-items-start"
                    :style="{ visibility: localData.color_option === 'solid' ? 'visible' : 'hidden' }"
                >
                    <ColorPicker v-model="localData.guide_line_color" label="Solid Color" @change="updateData" />
                </div>
            </div>
        </fieldset>
        <div style="visibility: hidden">
            <span style="font-family: 'Classic Cursive Dashed'"></span>
            <span style="font-family: 'Classic Cursive Guided'"></span>
            <span style="font-family: 'Classic Cursive Regular'"></span>
            <span style="font-family: 'Classic Cursive Stroked'"></span>
            <span style="font-family: 'Classic Print Dashed'"></span>
            <span style="font-family: 'Classic Print Guided'"></span>
            <span style="font-family: 'Classic Print Regular'"></span>
            <span style="font-family: 'Classic Print Stroked'"></span>
            <span style="font-family: 'Cursive Dashed'"></span>
            <span style="font-family: 'Cursive Regular'"></span>
            <span style="font-family: 'Cursive Stroked'"></span>
            <span style="font-family: 'D Nealian Print Dashed'"></span>
            <span style="font-family: 'D Nealian Print Guided'"></span>
            <span style="font-family: 'D Nealian Print Regular'"></span>
            <span style="font-family: 'D Nealian Print Stroked'"></span>
            <span style="font-family: 'Precursive Dashed'"></span>
            <span style="font-family: 'Precursive Regular'"></span>
            <span style="font-family: 'Precursive Stroked'"></span>
        </div>
    </div>
</template>

<script lang="ts">
import Vue from 'vue'
import { defineComponent, ComponentPublicInstance } from 'vue'
import { VueEditorData } from 'vue2-editor'
import uniqueId from 'lodash/uniqueId'
import Draggable from 'vuedraggable'
import {
    HANDWRITING_TYPE_SOLID,
    HANDWRITING_TYPE_STROCK,
    HANDWRITING_TYPE_TRACE,
    HANDWRITING_LINE_HEIGHT_MIN,
    HANDWRITING_LINE_HEIGHT_MAX,
    TYPE_CURSIVE,
    default_color,
} from '../../store/helpers/documentHelpers'
import FontSelect from '../../widgets/font-select.vue'
import ColorPicker from '../ColorPicker.vue'
import AdvancedOptionsToggleButton from '../buttons/AdvancedOptionsToggleButton.vue'
import OptionButtonSelector from '../../stories/components/buttons/OptionButtonSelector.vue'
import HandwritingInputItem from '../HandwritingInputItem.vue'
import HandWritingInputItemModel from '../../store/models/HandWritingInputItem'
import { HandWritingInputItem } from 'resources/js/types/handwritinginputitem'

interface HandwritingData {
    items: HandWritingInputItem[]
    cursive_style: string
    hw_font_cursive: string
    hw_font_print: string
    [key: string]: any
}

interface Data {
    showAdvancedOptions: boolean
    HANDWRITING_TYPE_SOLID: string
    HANDWRITING_TYPE_TRACE: string
    HANDWRITING_TYPE_STROCK: string
    HANDWRITING_LINE_HEIGHT_MIN: number
    HANDWRITING_LINE_HEIGHT_MAX: number
    uniqueId: string
    handwritingStyles: { label: string; value: string }[]
    newItem: HandWritingInputItem
    colorOptions: { label: string; value: string; isPremium: boolean; feature: string }[]
}

export default defineComponent({
    name: 'Handwriting',
    components: {
        ColorPicker,
        AdvancedOptionsToggleButton,
        FontSelect,
        OptionButtonSelector,
        HandwritingInputItem,
        Draggable,
    },
    props: {
        data: {
            type: Object,
            default: () => ({}),
        },
        index: {
            type: Number,
            required: true,
        },
        clickedIndex: {
            type: Object,
            default: () => ({ index: -1, clickCount: 0 }),
        },
    },
    data(): Data {
        return {
            showAdvancedOptions: false,
            HANDWRITING_TYPE_SOLID,
            HANDWRITING_TYPE_TRACE,
            HANDWRITING_TYPE_STROCK,
            HANDWRITING_LINE_HEIGHT_MIN,
            HANDWRITING_LINE_HEIGHT_MAX,
            uniqueId: '',
            handwritingStyles: [
                { label: 'Print', value: 'print' },
                { label: 'Cursive', value: 'cursive' },
            ],
            newItem: new HandWritingInputItemModel(),
            colorOptions: [
                { label: 'Solid', value: 'solid', isPremium: false, feature: 'color' },
                { label: 'Red/Blue', value: 'red_blue', isPremium: true, feature: 'color' },
            ],
        }
    },
    computed: {
        localData: {
            get() {
                return this.data
            },
            set(value: HandwritingData) {
                this.$emit('change', value)
            },
        },
        currentFont(): string {
            return this.localData[`hw_font_${this.localData.cursive_style}`]
        },
        hwFonts(): object[] {
            if (this.localData.cursive_style === TYPE_CURSIVE) {
                return this.$fonts.groupedHwCursiveFonts
            } else {
                return this.$fonts.groupedHwPrintFonts
            }
        },
        cursiveFont(): string {
            return this.localData[`hw_font_${this.localData.cursive_style}`]
        },
        lineHeightInches: {
            get(): number {
                return Number((this.localData.line_height / 96).toFixed(1))
            },
            set(value: number): void {
                this.localData.line_height = value * 96
                this.updateData()
            },
        },
        spacingInches: {
            get(): number {
                return Number((this.localData.spacing / 96).toFixed(1))
            },
            set(value: number): void {
                this.localData.spacing = value * 96
                this.updateData()
            },
        },
        defaultLineColor(): string {
            return default_color
        },
    },
    watch: {
        clickedIndex: {
            handler(val: { index: number; clickCount: number }) {
                if (val && val?.index >= 0) {
                    this.handleSwitchFocus({ index: val.index - 1, shiftKey: false })
                }
            },
            immediate: true,
            deep: true,
        },
    },
    created() {
        this.uniqueId = uniqueId('handwriting_')
    },
    mounted() {
        if (this.clickedIndex.index != null) this.handleSwitchFocus({ index: this.clickedIndex.index - 1, shiftKey: false })
    },
    methods: {
        updateData(): void {
            this.$emit('change', this.localData)
        },
        addItem(item: HandWritingInputItem): void {
            this.localData.items.push(item)
            this.updateData()
        },
        removeItem(index: number): void {
            this.localData.items.splice(index, 1)
            this.updateData()
            this.$nextTick(() => {
                const prevIndex = index - 1
                if (prevIndex >= 0) {
                    this.handleSwitchFocus({ index: prevIndex - 1, shiftKey: false })
                }
            })
        },
        duplicateItem(index: number): void {
            const item = this.localData.items[index]
            this.localData.items.splice(index + 1, 0, HandWritingInputItemModel.fromObject(item))
            this.updateData()
            this.$nextTick(() => {
                this.handleSwitchFocus({ index: index, shiftKey: false })
            })
        },
        onChangeFont({ font }: { font: string }): void {
            this.localData[`hw_font_${this.localData.cursive_style}`] = font
            this.updateData()
        },
        updateHandwritingStyle(style: string): void {
            this.localData.cursive_style = style
            this.updateData()
        },
        updateItemData(item: { index: number; data: HandWritingInputItem }): void {
            const index = item.index
            if (index < 0) {
                this.newItem = item.data
            } else {
                if (Boolean(this.localData.items[index])) {
                    Vue.set(this.localData.items, index, item.data)
                }
            }
            this.updateData()
        },
        handleSwitchFocus($event: { index: number; shiftKey: boolean }): void {
            const lastIndex = this.localData.items.length - 1

            this.$nextTick(() => {
                if (!$event.shiftKey && lastIndex === $event.index) {
                    const newItemRef = this.$refs['handwriting-item-new'] as
                        | ComponentPublicInstance<typeof HandwritingInputItem>
                        | undefined
                    const quillRef = newItemRef?.$refs[`widget_${this.newItem.id}_-1`] as unknown as VueEditorData
                    if (quillRef?.quill) {
                        quillRef.quill.root.focus()
                    }
                    return
                }

                const nextItemIndex = $event.shiftKey ? $event.index - 1 : $event.index + 1
                const itemId = this.localData.items[nextItemIndex]?.id
                const components = this.$refs[`${itemId}`] as
                    | ComponentPublicInstance<typeof HandwritingInputItem>[]
                    | undefined
                const nextItemRef = components?.[0]
                const quillRef = nextItemRef?.$refs[`widget_${itemId}_${nextItemIndex}`] as unknown as VueEditorData
                if (quillRef?.quill) {
                    quillRef.quill.root.focus()
                }
            })
        },
        updateColorOption(option: string): void {
            this.localData.color_option = option
            this.updateData()
        },
        resetLineColor() {
            this.localData.guide_line_color = this.defaultLineColor
            this.updateData()
        },
    },
})
</script>

<style scoped>
.w-60 {
    width: 60%;
}

.reset-btn {
    margin-top: 0.375rem !important;
}
</style>
