<template>
    <div class="my-3">
        <div class="btn-toggle-settings w-100">
            <button
                class="btn rounded btn-sm text-uppercase"
                aria-label="Toggle Options"
                :class="active ? 'btn-primary' : 'btn-outline-primary'"
                :disabled="disabled"
                @click="handleClick"
            >
                <span>Options</span>
                <IconGear class="icon-gear" />
                <b-icon icon="chevron-down" />
            </button>
        </div>
        <div v-if="active" class="advanced-options-wrapper">
            <slot />
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import IconGear from '../../stories/components/icons/IconGear.vue'

export default defineComponent({
    name: 'AdvancedOptionsToggleButton',
    components: { IconGear },
    props: {
        active: {
            type: Boolean,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        handleClick() {
            this.$emit('click')
        },
    },
})
</script>

<style lang="scss" scoped>
@import 'Scss/base.scss';

.btn-toggle-settings {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px 0;
    z-index: 1;

    &:before,
    &:after {
        content: ' ';
        width: 50px;
        border-top: 4px double #adb5bd;
    }

    .icon-gear {
        margin-right: 0.75rem;
        margin-left: 0.3125rem;
    }
}

.btn-outline-primary:disabled:hover {
    background-color: transparent !important;
    color: var(--primary) !important;
    border-color: var(--primary) !important;
}

.advanced-options-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    & > * + * {
        margin-top: 0.5rem;
    }

    & > :first-child {
        margin-top: 1rem;
    }

    & > :last-child {
        margin-top: 1rem;
    }
}
</style>
