<template>
    <div>
        <Draggable v-model="options" v-bind="{ handle: '.-option-handle', animation: 500 }" @end="updateData">
            <div
                v-for="(option, index) in options"
                :key="index"
                class="mb-2"
                @mouseenter="updateState('hovered', index, true)"
                @mouseleave="updateState('hovered', index, false)"
            >
                <MultiOptionItem
                    v-model="option.term"
                    :ref-id="`bingo-word-item-${index}`"
                    :image-ref-id="`bingo-word-image-btn-${index}`"
                    :image-id="option.term_image"
                    :index="index"
                    :delete-button-class="deleteButtonClass(index)"
                    handle
                    inline-image
                    placeholder="Enter your word"
                    handle-class="-option-handle"
                    group-class="bingo-word-item-group"
                    class="mb-2"
                    @input="updateData(index, $event)"
                    @enter="focusNext(index)"
                    @delete="removeOption(index)"
                    @deleteImage="clearInlineImage(index)"
                    @focus="updateState('active', index, true)"
                    @blur="updateState('active', index, false)"
                >
                    <template #before-input>
                        <span class="f-14 font-weight-bold mr-2 text-right" style="width: 1.215rem">{{ index + 1 }}.</span>
                    </template>
                </MultiOptionItem>
                <div>
                    <b-form-input
                        v-if="option.prompt"
                        :ref="'bingo-word-prompt-' + index"
                        v-model="option.definition"
                        placeholder="Add prompt for call list"
                        size="sm"
                        :style="{
                            width: `${optionWidth}`,
                            marginLeft: '3.8438rem',
                        }"
                        @input="updateData(index, $event)"
                        @keydown.enter="focusNext(index, true)"
                    ></b-form-input>
                </div>
            </div>
        </Draggable>
        <div class="d-flex my-2">
            <b-input-group :style="{ width: `${optionWidth}`, marginLeft: '3.825rem' }">
                <b-input
                    ref="add-new--option"
                    v-model="newOption"
                    size="sm"
                    type="text"
                    list="add-new--option"
                    placeholder="+ Add new word"
                />
                <b-input-group-append>
                    <InlineImageBtn
                        :input-index="options.length"
                        column="term"
                        btn-class="rounded-right-small"
                        @click="addOption"
                    />
                </b-input-group-append>
            </b-input-group>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue'
import Draggable from 'vuedraggable'
import { mapGetters } from 'vuex'
import { debounce } from 'lodash'
import InlineImageBtn from './AddInlineImageButton.vue'
import MultiOptionItem from './MultiOptionItem.vue'
import WithItemEventState from '../mixins/WithItemEventState'

export default defineComponent({
    name: 'BingoWordItems',
    components: {
        Draggable,
        InlineImageBtn,
        MultiOptionItem,
    },
    mixins: [WithItemEventState],
    props: {
        value: {
            type: Array,
            default: () => [],
        },
        prompt: {
            type: [Boolean, Number],
            default: false,
        },
    },
    data() {
        return {
            newOption: '',
            options: [],
            optionWidth: '72%',
        }
    },
    computed: {
        ...mapGetters({
            currentWidget: 'document/currentWidget',
            style: 'document/documentStyle',
            bingoItems: 'document/bingoItems',
            bingo: 'document/bingo',
        }),
        dimension() {
            return this.bingo.dimension
        },
    },
    watch: {
        newOption(val) {
            if (val.trim()) {
                this.addOption(val.trim())
            }
        },
        style: {
            deep: true,
            handler() {
                this.updateData()
            },
        },
        value() {
            this.options = this.value
        },
        currentWidget: {
            handler(val) {
                const bingoWordInput = document.getElementById(`bingo-word-item-${val.focusedItem.index}`)
                if (val.openBingoWords && Boolean(bingoWordInput)) {
                    bingoWordInput?.focus()
                }
            },
        },
    },
    mounted() {
        this.options = this.value
        this.$nextTick(() => {
            const firstInput = document.getElementById('bingo-word-item-0')
            if (firstInput) {
                let width = firstInput.offsetWidth
                const firstInputImageBtn = document.getElementById('bingo-word-image-btn-0')
                if (firstInputImageBtn) {
                    width += firstInputImageBtn.offsetWidth
                }
                this.optionWidth = `${width}px`
            } else {
                this.optionWidth = `72%`
            }
        })
    },
    methods: {
        updateData() {
            this.emitEvent()
        },
        emitEvent: debounce(function () {
            this.$emit('input', this.options)
        }, 500),
        removeOption(index) {
            if (`${this.options[index]?.term_image}`.length) {
                this.options[index].term_image = ''
            }
            this.options.splice(index, 1)
            this.$emit('input', this.options)
        },
        addOption(val = '') {
            this.options.push({
                term: val,
                term_image: null,
                prompt: this.prompt,
                definition: '',
            })

            this.$emit('input', this.options)

            this.$nextTick(() => {
                let index = this.options.length - 1
                const bingoWordInputElement = document.getElementById(`bingo-word-item-${index}`)
                bingoWordInputElement?.focus()
                this.newOption = ''
            })
        },
        focusNext(index, isPrompt = false) {
            if (this.options[index].prompt && !isPrompt) {
                document.getElementById(`bingo-word-item-${index}`)?.focus()
            } else if (index < this.options.length - 1) {
                document.getElementById(`bingo-word-item-${index + 1}`)?.focus()
            } else {
                this.$refs['add-new--option'].focus()
            }
        },
        clearInlineImage(index) {
            this.options[index].term_image = ''
            this.updateData()
        },
    },
})
</script>
